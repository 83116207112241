import * as React from "react"
import Layout from "../components/layout"
import {StaticImage} from "gatsby-plugin-image";
import '../styles/styles.scss'
import {Parallax, ParallaxBanner} from "react-scroll-parallax";
import ContactForm from "../components/contactForm";
import {useState} from "react";

const ServiciosAdminPage = () => {
    const [modalFormVisible, setModalFormVisible] = useState(false)

    return (
        <Layout>

            <div className={'slider cover-simple serviciosadmin'}>
                <div className={'item'}>
                    <div className={'sliderleft'}>
                        <p className={'especial3 especialservicios'}>SERVICIOS</p>
                        <h3 className={'h3servicios'}>Administrados de Impresión</h3>
                        <p className={'contenido1 contenidoservicios'}>Transformamos tu infraestructura de impresión, en
                            una plataforma que provée información precisa. Te ayudamos con la gestión y administración
                            proactiva de insumos. </p>
                        <p className={'contenido1 contenidoservicios'}>¡Déjanos encargarnos de la generación de tus
                            documentos!</p>
                    </div>
                    <div className={'sliderright'}>
                        <StaticImage src="../images/dibujo-admin-slider.png" alt="T.I.O. ALEMAR"/>
                    </div>

                </div>
            </div>

            <div className={'image-info process'}>
                <Parallax speed={-10} className={'bloqueleft40 float left'}>
                    <StaticImage className={'img'} src="../images/admin-imgleft1.png" alt="Servicios ALEMAR"/>
                </Parallax>
                <Parallax speed={10} className={'bloqueright2 bloqueright2servicios right'}>
                    <h4 className={'h4servicios'}>Auditamos el consumo de tus dispositivos de impresión.
                        ¡Ya no inviertas en activos de tecnología! Te invitamos a convertirlos en un gasto
                        operativo.</h4>
                    <p className={'especial2'}>Te brindamos un acompañamiento recurrente.</p>
                </Parallax>
            </div>

            <div className={'wrapperbase quote'}>
                <h1 data-sal="slide-right"
                    data-sal-duration={"600"}>
                    <span className={'color-cyan'}>Reduce</span> la barrera de la complejidad..
                </h1>
            </div>

            <div className={'wrapperbase'}>
                <div className={'Home4valores valoresservicios'}>
                    <div className={'HomeCard CardServicios'}
                         data-sal="slide-up"
                         data-sal-delay="100"
                         data-sal-easing="ease">
                        <StaticImage className={'img'} src="../images/servicios-eficacia.png" alt="ALEMAR"/>
                        <p className={'especial3'}>Eficacia</p>
                        <p className={'contenido1'}>La asistencia predictiva, nos permite anticipar y evitar las
                            interrupciones antes de que sucedan.</p>
                    </div>
                    <div className={'HomeCard CardServicios'}
                         data-sal="slide-up"
                         data-sal-delay="200"
                         data-sal-easing="ease">
                        <StaticImage className={'img'} src="../images/servicios-seguridad.png" alt="ALEMAR"/>
                        <p className={'especial3'}>Seguridad</p>
                        <p className={'contenido1'}>Aplicamos las mejores prácticas de la industria (ITIL).</p>
                    </div>
                    <div className={'HomeCard CardServicios'}
                         data-sal="slide-up"
                         data-sal-delay="300"
                         data-sal-easing="ease">
                        <StaticImage className={'img'} src="../images/servicios-simpleza.png" alt="ALEMAR"/>
                        <p className={'especial3'}>Simpleza</p>
                        <p className={'contenido1'}>Apoyamos su visión de la simplicidad, la seguridad, el ahorro y la
                            sostenibilidad de impresión. </p>
                    </div>
                </div>
            </div>

            <ParallaxBanner
                layers={[{
                    speed: 30,
                    children: (
                        <StaticImage src="../images/bg-admin.png" alt="Home ALEMAR"/>
                    )
                }]}
                className="bg-parallax"
            />

            <div className={'servicios-help'}>
                <div className={'left'} data-sal="slide-right"
                     data-sal-duration={"600"}>
                    <StaticImage className={'img'} src="../images/dibujo-admin-left.png" alt="ALEMAR"/>
                </div>
                <div className="right" data-sal="slide-left" data-sal-delay={"200"}
                     data-sal-duration={"600"}>
                    <div className="content">
                        <h5>Te ayudamos a controlar y administrar la generación de documentos.</h5>
                        <p className={'contenido1'}>Te ayudamos a optimizar tu infraestructura.</p>
                        <button onClick={() => setModalFormVisible(true)} className="btn-push-black"><span>Agenda tu asesoría sin costo aquí</span>
                        </button>
                    </div>
                    <StaticImage className={'img'} src="../images/dibujo-admin-right.png" alt="ALEMAR"/>

                </div>
            </div>

            <div className={'servicios-administracion'}>
                <div className={'first'}>
                    <h3 className={'left'}>Administración
                        proactiva de dispositivos de impresión.</h3>
                    <div className={'right'}>
                        <p className={'especial2 pnosotros1 pservicios padmon'}>¿Sientes que el tema de impresión está
                            fuera de control? Acércate a nosotros.</p>
                        <hr className={'padmon'}></hr>
                        <ul className={'padmon'}>
                            <li>Reducción de costos</li>
                            <li>Precisión en la generación de documentos</li>
                            <li>Aumento de la productividad</li>
                            <li>Administración sobre la impresión</li>
                            <li>Reducción de uso de papel</li>
                            <li>Mitigación de riesgos de seguridad</li>
                        </ul>
                    </div>
                </div>
                <div className={'bottom'}>
                    <div className="descriptions">
                        <div className="description efficiency"
                             data-sal="slide-up"
                             data-sal-delay="300"
                             data-sal-easing="ease">
                            <p className={'especial3'}>Mejor eficiencia en la generación de documentos</p>
                        </div>
                        <div className={'description proactive'}
                             data-sal="slide-up"
                             data-sal-delay="300"
                             data-sal-easing="ease">
                            <p className={'especial3'}>Gestión proactiva del entorno</p>
                        </div>
                        <div className={'description optimization'}
                             data-sal="slide-up"
                             data-sal-delay="300"
                             data-sal-easing="ease">
                            <p className={'especial3'}>Optimización</p>
                        </div>
                    </div>

                    <StaticImage className={'img'} src="../images/Eficiencia-admon.png" alt="Servicios ALEMAR"/>
                </div>
            </div>

            <div className={'wrapperbase services-sustentability'}>
                <div className={'contenedor'} data-sal="slide-up" data-sal-delay={"200"}
                     data-sal-duration={"600"}>
                    <div className="left">
                        <h5 className={'sustentable'}>Programas sustentables de <span>ayuda a los clientes</span></h5>
                        <ul className={'padmon ulsustentable'}>
                            <li>Colaboración de <span>programas ecológicos</span>.</li>
                            <li>Siempre usamos consumibles <span>originales</span> de fabricante.</li>
                            <li>Recolección de <span>consumibles de impresión</span>.</li>
                            <li>Apoyo para <span>certificaciones</span> medio ambientales.</li>
                        </ul>
                        <hr className={'hrblanco'}></hr>
                        {/*<ul className={'wrapperabajosustentable'}>
                            <li>
                                <h4>+40</h4>
                                <p className={'contenido1'}>dato relevante</p>
                            </li>
                            <li>
                                <h4>100</h4>
                                <p className={'contenido1'}>otro dato</p>
                            </li>
                        </ul>*/}
                    </div>
                    <StaticImage className={'right'} src="../images/trashcan.png" alt="Servicios ALEMAR"/>

                </div>
            </div>

            {modalFormVisible &&
                <div className={'modal form-contact'}>
                    <div className="bg-black content">
                        <button onClick={() => setModalFormVisible(false)}>x</button>
                        <ContactForm
                            asunto={'Me gustaría una asesoría sobre servicios de servicios administrados de impresión'}/>
                    </div>
                </div>
            }

        </Layout>
    )
}

export default ServiciosAdminPage

export const Head = () => {
    return (
        <>
            <title>Administra todas las impresiones de tu negocio</title>
            <meta name="description" content="Transformamos tu infraestructura de impresión, en una plataforma que proveé información precisa en tiempo y forma. Al auditar tu consumo en tus dispositivos ya no tendrás gastos innecesarios."/>
        </>
    )
}
